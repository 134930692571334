/* Journey.css */

.journey-container {
  background-color: #000;
  color: #fff;
  padding: 5vh 0;
  text-align: center;
  border-radius: 4em 4em 0 0;
  padding-left: 2vw;
  padding-right: 2vw;
  position: relative;
}

.journey-heading {
  font-size: 4.6vh;
  font-weight: bold;
}

.journey-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vh;
  position: relative;
}

.journey-blue-line {
  border: none;
  background-color: #43c5e4;
  height: 2%;
  width: 80vw;
  position: absolute;
  top: 40%;
  left: 10%;
  transform: translateY(-50%);
}

.journey-checkpoint {
  flex: 1;
  position: relative;
}

.checkpoint-bullet {
  width: 0.8vw;
  height: 0.8vw;
  background-color: #43c5e4;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.3s ease, height 0.3s ease;
}

.checkpoint-text {
  margin-top: 6vh;
  font-weight: 500;
  text-align: center;
  font-size: 2.5vh;
}

.journey-divider {
  display: none;
  margin-top: 5vh;
  width: 7em;
}

.moving-bullet-size .checkpoint-bullet {
  width: 1.5vw;
  height: 1.5vw;
}

.normal-bullet-size .checkpoint-bullet {
  width: 0.8vw;
  height: 0.8vw;
}

.program-for {
  margin-top: 5vh;
  padding: 8vh;
}

.program-heading {
  font-size: 6vh;
  font-weight: 900;
  color: #43c5e4;
}

.customer-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2vh;
}

.customer-card {
  width: 40vw; /* Adjust width as needed */
  height: 20vh; /* Adjust height as needed */
  background-color: #000;
  color: #fff;
  border: 0.2vw solid #fff;
  border-radius: 2em;
  margin-bottom: 2vh;
  display: flex;
}

.left-customer-side {
  flex: 0 0 20%;
  padding: 2vh;
}

.right-customer-side {
  flex: 2;
  padding: 2vh;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.customer-title {
  font-weight: bold;
  font-size: 3vh;
  padding-bottom: 1vh;
}

.customer-cards {
  justify-content: center; /* Center the cards horizontally */
  justify-content: space-between;
}

.customer-card:last-child {
  margin: 0 auto; /* Center the last card */
}

.customerlogo {
  display: block;
  margin: 2vw auto;
  width: 4em;
  height: 3em;
}

.customerlogo1 {
  display: block;
  margin: 2vw auto;
  width: 4em;
  height: 4em;
}

.customerlogo2 {
  width: 3em;
  height: 4em;
}

.customer-description {
  font-size: 0.8em;
  text-align: left; /* Center align description */
  margin-top: 1vh; /* Add margin between title and description */
}

@media (max-width: 768px) {
   .journey-container{
      position: relative;
      top: 7vh;
    }
    .journey-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2vh;
      left: 0;
      position: relative;
      flex-direction: column;
    }
  
    .journey-blue-line {
      display: none;
    }
  
    .journey-checkpoint:nth-child(odd) .checkpoint-text {
      text-align: left;
      font-size: 2.3vh;
    }
  
    .journey-checkpoint:nth-child(even) .checkpoint-text {
      text-align: right;
      font-size: 2.3vh;
    }
    .checkpoint-bullet {
      display: none;
  }
    .moving-bullet {
      display: none;
    }
    .program-for {
      display: flex;
      padding: 3vh;
      flex-direction: column;
      align-items: center;
  }
  
    .program-heading {
      font-size: 2em;
    }
  
    .customer-cards {
      flex-direction: column;
      align-items: center;
    }
  
    .customer-card {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column; /* Stack elements vertically */
      margin-bottom: 20px;
      border: none; /* Remove border */
    }
  
    .left-customer-side,
    .right-customer-side {
      text-align: center; /* Center align content */
      padding: 10px;
    }
  
    .customerlogo {
      width: 2.5em;
      height: 2.5em;
      margin: 0 auto; /* Center logo */
    }
  
    .customer-title {
      font-size: 1.2em;
      margin-top: 10px; /* Add margin between logo and title */
    }
  
    .customer-description {
      font-size: 0.8em;
      text-align: center; /* Center align description */
      margin-top: 10px; /* Add margin between title and description */
    }
}

/* Styles for 601px to 768px */


/* Styles for 769px to 992px */
@media screen and (min-width: 769px) and (max-width: 992px) {
  .journey-container {
    padding: 4vh;
  }

  .journey-heading {
    font-size: 3.4vh;
  }

  .checkpoint-text {
    font-size: 2.1vh;
  }

  .program-for {
    padding: 4vh;
  }

  .program-heading {
    font-size: 3.8vh;
  }

  .customer-card {
    width: 80vw;
  }
  .customer-card:last-child {
    margin: 0 ;
}
}

/* Styles for 993px to 1200px */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .journey-container {
    padding: 5vh;
  }
  .customer-card:last-child {
    margin: 0;
}
.customer-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2vh;
  flex-direction: column;
  align-items: center;
}
  .journey-heading {
    font-size: 5vh;
  }

  .checkpoint-text {
    font-size: 2.2vh;
  }

  .program-for {
    padding: 5vh;
  }

  .program-heading {
    font-size: 5vh;
  }

  .customer-card {
    width: 75vw;
  }
}
