/*responsive : yes*/
/*responsive test-status: */

.SC {
  position: relative;
  width: 100vw;
  height: 45vh;
  background-color: #43c5e4;
  overflow: hidden;
}

.EmailInput {
  flex: 1;
  padding: 2vh;
  border: none;
  font-size: 2.5vh;
}

.SubscribeButton {
  padding: 2vh 4vw;
  background-color: #ffffff;
  color: #000000;
  border: none;
  font-size: 2.5vh;
  cursor: pointer;
}

.SubscribeButton:hover {
  font-weight: 600;
}

.SubscribeButton:active {
  background-color: #0056b3;
  transform: translateY(0.2vh);
}

.EmailSide {
  left: 18vh;
  top: -3vh;
  align-items: center;
  display: flex;
  position: relative;
  width: 50vw;
  height: 100%;
  overflow: hidden;
}

.SubscribeWhole {
  display: flex;
  align-items: center;
  height: 100%;
}

.SubscribeTextBox {
  width: 30vw;
  margin: 0 auto;
  margin-left: 10%;
}

.SubscribeTopic {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 5vh;
  color: #ffffff;
}

.SubscribeDescription {
  font-family: Montserrat;
  font-size: 2.4vh;
  color: #ffffff;
  margin-top: 4vh;
}

@media (orientation: portrait) {
  .SC {
      height: auto;
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 4vh;
  }

  .SubscribeWhole {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
  }

  .SubscribeTextBox {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 4vh;
  }

  .SubscribeTopic {
      font-size: 4.6vh;
      text-align: center;
  }

  .SubscribeDescription {
      font-size: 2.6vh;
      margin-top: 1vh;
      text-align: center;
  }

  .EmailSide {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      padding: 0 2vw;
      left: 0;
      top: 0;
  }

  .EmailInput {
    margin-bottom: 2vh;
    font-size: 1.5vh;

  }

  .SubscribeButton {
      margin-bottom: 4vh;
      font-size: 1.5vh;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .SC {
      height: auto;
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 4vh;
  }

  .SubscribeWhole {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
  }

  .SubscribeTextBox {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 4vh;
  }

  .SubscribeTopic {
      font-size: 4.6vh;
      text-align: center;
  }

  .SubscribeDescription {
      font-size: 2.6vh;
      margin-top: 1vh;
      text-align: center;
  }

  .EmailSide {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: auto;
      padding: 0 2vw;
      left: 0;
      top: 0;
  }

  .EmailInput {
      margin-bottom: 2vh;
  }

  .SubscribeButton {
      margin-bottom: 4vh;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .SC {
      height: 50vh;
  }

  .EmailSide {
    left: 5vh;

      top: -3vh;
      align-items: center;
      display: flex;
      position: relative;
      width: 50vw;
      height: 100%;
      overflow: hidden;
  }

  .SubscribeWhole {
      display: flex;
      align-items: center;
      height: 100%;
  }

  .SubscribeTextBox {
      width: 30vw;
      margin: 0 auto;
      margin-left: 10%;
  }

  .SubscribeTopic {
      font-family: Montserrat;
      font-weight: 700;
      font-size: 5vh;
      color: #ffffff;
  }

  .SubscribeDescription {
      font-family: Montserrat;
      font-size: 2.4vh;
      color: #ffffff;
      margin-top: 4vh;
  }
}
