/* LearnPros.css */

.learn-pros {
    position: relative;
    width: 100%;
    height: 40vw;
    background-color: black;
}

.learn-pros .column:hover {
    animation-play-state: paused;
}

.learn-pros .column1:hover {
    animation-play-state: paused;
}

.content-learn {
    display: flex;
    align-items: center;
    height: 100%;
}

.text-container-learn {
    width: 32%;
    margin: 0 auto;
    margin-left: 10%;
    text-align: left;
}

.title-learn {
    font-family: 'Montserrat-Bold', sans-serif;
    font-weight: bold;
    font-size: 2.5vw;
    color: white;
}

.subtitle-learn {
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 2.5vw;
    color: white;
    
}

.description-learn {
    font-family: Montserrat;
    font-size: 1.2vw;
    color: white;
    margin-top: 1vw;
}

.image-container-learn {
    position: relative;
    width: 68%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    padding-left: 10%;
    padding-right: 10%;
}

.image-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 2vw;
}

.image-wrapper img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 1em;
    transition: transform 0.5s ease;
}

.image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 1vw;
    box-sizing: border-box;
    border-radius: 0 0 1em 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.overlay-text {
    flex: 1;
    padding-right: 1vw;
}

.overlay-text .name {
    font-size: 1.5vw;
    font-weight: bold;
}

.overlay-text .designation,
.overlay-experience {
    font-size: 1vw;
}



@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-100%);
    }
}

.learn-pros .column {
    animation: slideUp 17s linear infinite;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-right: 5%;
}

.learn-pros .column1 {
    animation: slideDown 17s linear infinite;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

@media screen and (orientation: portrait) {
    .learn-pros {
        height: 80vh;
    }
    .content-learn {
        flex-direction: column; /* Stack the content vertically */
    }

    .text-container-learn {
        width: 90%;
        margin: 6vw;
        padding: 6vw;
        text-align: center;
    }

    .image-container-learn {
        width: 80%;
        padding-left: 5vh;
        padding-right: 5vh;
        
    }

    .title-learn {
        font-size: 3vh;
    }
    .subtitle-learn {
        font-size: 3vh;
    }
    
    .description-learn {
        font-size: 2vh;
    }
    .overlay-text .name {
        font-size: 2.3vw;
    }
    
    .overlay-text .designation,
    .overlay-experience {
        font-size: 1.8vw;
    }

}