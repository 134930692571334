/* components/Hero.css */
.course-hero {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%, /* White */
        
      rgba(173, 216, 230, 1) 25%, /* Light blue */
      rgba(135, 206, 250, 1) 50%, /* Light sky blue */
      rgba(70, 130, 180, 1) 100% /* Medium steel blue (peak blue) */
    );
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; /* Adjust the height as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000000; /* Text color on the hero section */
  }
  