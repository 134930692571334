/* Button.css */
.custom-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin: 20px; /* Adjust the margin as needed */
  border: none;
  background-color: #ffffff; /* Adjust the background color as needed */
  color: #3498db; /* Adjust the text color as needed */
  font-family:Montserrat;
  border-radius: 8px; /* Adjust the border-radius for rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0f2ff; /* Adjust the hover background color as needed */
    transform: scale(1.1);
}
  

  span {
    margin-right: 10px;
  }

  .icon-container {
    width: 30px;
    height: 30px;
    background-color: #3498db;
     /* Adjust the background color of the circle as needed */
    border-radius: 50%; /* Ensure it's a perfect circle */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow-circle {
    font-size: 16px; /* Adjust the font size of the arrow */
  }
}
