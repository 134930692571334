.intern-options {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .nav-button {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    color: #000;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    z-index: 10; /* Ensure button is on top of other elements */
  }
  
  .nav-button.left {
    left: 10px;
  }
  
  .nav-button.right {
    right: 10px;
  }
  
  .nav-button:hover {
    color: #888; /* Change color on hover if needed */
  }
  
  .intern-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  