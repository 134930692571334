/* Base styles */
.course-result-container {
  text-align: center;
  position: relative;
  margin-top: 0;
  max-width: 75vw;
  box-shadow: 0 0 1vw rgba(0, 0, 0, 0.1);
  height: 100%; /* Adjust the height as necessary */
  border-radius: 6em 6em 0 0;
  background-color: #ffffff;
  display: flex;
  z-index: 1; /* Ensure CourseResult is above Journey */
}

.result_hero {
  background: linear-gradient(
    to bottom,
    rgb(70, 130, 180) 0%,
    rgba(135, 206, 250, 1) 25%,
    rgba(173, 216, 230, 1) 70%,
    rgba(255, 255, 255, 1) 100%
  );
  position: relative;
  width: 100vw;
  height: 75vh; /* Ensure it takes up the full viewport height */
  display: flex; /* Add display: flex to use align-items and justify-content */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}

.left-section {
  flex: 1;
  text-align: left;
  padding: 2vh;
  display: flex;
  align-items: center;
}

.left-section h2 {
  font-family: 'Montserrat-Bold', sans-serif;

  margin-top: 2vh;
  color: black;
  font-size: 4vh;
}

.right-section {
  flex: 2 1;
  display: flex;
  padding: 3vh;
  flex-wrap: wrap;
  align-content: center;
}

.result-card {
  width: 20vw;
  height: 20vh;
  padding: 2vh;
  margin-bottom: 2vh;
  margin-left: auto;
  border-radius: 2em;
  text-align: left;
  background: linear-gradient(to bottom right, #ffffff, #43c5e4);
}

.result-card h3 {
  font-size: 2vh;
  color: #000;
  font-weight: bold;
}

.result-card p {
  font-size: 1.6vh;
  color: #000;
}

.logo {
  width: 6vh;
  height: 6vh;
  display: block;
  background: none;
}

.logo1 {
  width: 6vh;
  height: 6vh;
  display: block;
  background: none;
}

/* Mobile view styles */
@media screen and (max-width: 768px) {
  .course-result-container {
    flex-direction: column;
    height: auto; /* Allow height to adjust based on content */
    padding: 2vh;
    max-width: 90vw;
    border-radius: 3em 3em 0 0;
    margin-top: 10vh;
    position: relative;
    z-index: 1;
  }
  .result_hero {
    top: -3vh;
    background: linear-gradient(to bottom, rgb(70, 130, 180) 0%, rgba(135, 206, 250, 1) 25%, rgba(173, 216, 230, 1) 70%, rgba(255, 255, 255, 1) 100%);
    position: relative;
    width: 100vw;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
  .left-section {
    display: flex;
    width: 100%;
    padding: 1vh;
    text-align: center;
    justify-content: center;
  }

  .left-section h2 {
    font-size: 2.5vh;
    margin-top: 0;
  }

  .right-section {
    width: 100%;
    padding: 0;
    flex-direction: row;
    align-items: center;
  }

  .result-card {
    width: 35vw;
    height: auto;
    margin-bottom: 2vh;
    border-radius: 2em;
    text-align: left;
    background: linear-gradient(to bottom right, #ffffff, #43c5e4);
  }

  .result-card h3 {
    color: #000;
    font-weight: bold;
  }

  .result-card p {
    color: #000;
  }

  .logo {
    width: 4em;
    height: 4em;
    display: block;
    background: none;
  }

  .logo1 {
    width: 3em;
    height: 4em;
    display: block;
    background: none;
  }
}

/* Styles for 601px to 768px */
@media screen and (min-width: 601px) and (max-width: 768px) {
  .course-result-container {
    max-width: 85vw;
  }

  .left-section h2 {
    font-size: 3vh;
  }

  .result-card {
    width: 35vw;
    height: auto;
  }
}

/* Styles for 769px to 992px */
@media screen and (min-width: 769px) and (max-width: 992px) {
  .course-result-container {
    flex-direction: column;
    height: auto; /* Allow height to adjust based on content */
    padding: 2vh;
    max-width: 90vw;
    border-radius: 3em 3em 0 0;
    position: relative;
    z-index: 1;
  }
  .result_hero {
    top: -1vh;
    background: linear-gradient(to bottom, rgb(70, 130, 180) 0%, rgba(135, 206, 250, 1) 25%, rgba(173, 216, 230, 1) 70%, rgba(255, 255, 255, 1) 100%);
    position: relative;
    width: 100vw;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
  .left-section {
    display: flex;
    width: 100%;
    padding: 1vh;
    text-align: center;
    justify-content: center;
  }

  .left-section h2 {
    font-size: 2.5vh;
    margin-top: 0;
  }

  .right-section {
    width: 100%;
    padding: 0;
    flex-direction: row;
    align-items: center;
  }

  .result-card {
    width: 35vw;
    height: auto;
    margin-bottom: 2vh;
    border-radius: 2em;
    text-align: left;
    background: linear-gradient(to bottom right, #ffffff, #43c5e4);
  }

  .result-card h3 {
    color: #000;
    font-weight: bold;
  }

  .result-card p {
    color: #000;
  }

  .logo {
    width: 4em;
    height: 4em;
    display: block;
    background: none;
  }

  .logo1 {
    width: 3em;
    height: 4em;
    display: block;
    background: none;
  }
}

/* Styles for 993px to 1200px */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .course-result-container {
    flex-direction: column;
    height: auto; /* Allow height to adjust based on content */
    padding: 2vh;
    max-width: 90vw;
    border-radius: 3em 3em 0 0;
    position: relative;
    z-index: 1;
  }
  .result_hero {
    top: -1vh;
    background: linear-gradient(to bottom, rgb(70, 130, 180) 0%, rgba(135, 206, 250, 1) 25%, rgba(173, 216, 230, 1) 70%, rgba(255, 255, 255, 1) 100%);
    position: relative;
    width: 100vw;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
  .left-section {
    display: flex;
    width: 100%;
    padding: 1vh;
    text-align: center;
    justify-content: center;
  }

  .left-section h2 {
    font-size: 2.5vh;
    margin-top: 0;
  }

  .right-section {
    width: 100%;
    padding: 0;
    flex-direction: row;
    align-items: center;
  }

  .result-card {
    width: 35vw;
    height: auto;
    margin-bottom: 2vh;
    border-radius: 2em;
    text-align: left;
    background: linear-gradient(to bottom right, #ffffff, #43c5e4);
  }

  .result-card h3 {
    color: #000;
    font-weight: bold;
  }

  .result-card p {
    color: #000;
  }

  .logo {
    width: 4em;
    height: 4em;
    display: block;
    background: none;
  }

  .logo1 {
    width: 3em;
    height: 4em;
    display: block;
    background: none;
  }
}
