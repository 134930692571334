/* Curriculum.css */

.curriculum-outer {
  padding: 4vh 4vw;
  background-color: #000;
  color: #fff;
  min-height: 100vh; /* Set minimum height to 100% of the viewport height */
}
.downward-arrow {
  width: 1.5vh;
  height: 1vh;
  border-left: 0.5vh solid black;
  border-bottom: 0.5vh solid black;
  transform: rotate(-45deg);
  
  /* margin: 20px; */
}
.curriculum-core {
  text-align: left;
  margin: auto;
  padding: 3em 7vw;
  max-width: 75vw;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: fit-content;
  border-radius: 35px;
  background: linear-gradient(to bottom, #ffffff, #43c5e4);
  display: flex;
}

.curriculum-core.active {
  height: 35%;
}

.heading {
  font-size: 5vh;
  font-weight: bold;
  color: #000;
  text-align: left;
  width: 32vw;
  margin: 0 auto;
  margin-top: 8vh;
  font-family: 'Montserrat-Bold', sans-serif;

}
@font-face {
  font-family: 'Montserrat-Medium-Bold';
  src: url('../../Resources/Fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
.modules {
  margin-top: 2vh;
  position: relative;
  width: 60vw; /* Adjust the width as needed */
  height: 100%;
}

.module {
  width: 100%;
  background-color: transparent;
  color: black;
  border-radius: 1em;
  margin-bottom: 1vh;
  padding: 2vh;
  cursor: pointer;
  transition: background-color 0.3s;
}

.module:hover {
  font-weight: bold;
}

.active {
  font-weight: normal;
}

.module-info {
  display: flex;
  margin-bottom: 1vh;
}

.module-number {
  font-size: 3vh;
  width: 22.5vw;
}

.module-name {
  font-family: 'Montserrat-Medium-Bold', sans-serif;

  font-size: 2.5vh;
  display: block;
  width: 100%;
  white-space: normal;
}

.module-details {
  margin-top: 1vh;
  padding-left: 26%;
}

ul {
  list-style-type: disc;
  padding-left: 4vw;
}

li {
  margin-bottom: 0.5vh;
}

.cta-section {
  text-align: center;
  margin-top: 5vh;
  align-items: center; /* Center items horizontally */
}

.cta-text {
  font-size: 4em;
  font-weight: bold;
  color: #fff;
  margin-bottom: 2vh;
}

.price-box {
  width: 28vw;
  font-size: 4.8vh;
  font-weight: bold;
  color: #fff;
  background-color: transparent;
  border: 0.2vw solid #43c5e4;
  border-radius: 1em;
  padding: 2vh 4vw;
  margin-bottom: 4vh;
  transition: background-color 0.3s;
  align-self: center;
  margin-left: 27vw;
}

.price-box:hover {
  background-color: #43c5e4;
}

.apply-button {
  width: 30vw;
  font-size: 2.4vh;
  font-weight: bold;
  color: #fff;
  background-color: #43c5e4;
  border: none;
  border-radius: 1em;
  padding: 2vh 4vw;
  cursor: pointer;
  transition: background-color 0.3s;
}

.apply-button:hover {
  background-color: #2aa3b8;
}

@media screen and (max-width: 768px) {

  .heading {
    font-size: 2em;
    font-weight: bold;
    color: #000;
    text-align: left;
    margin-top: 0;
    margin-bottom: 2vh; /* Add margin bottom to separate from the content */
  }
  
  .curriculum-core {
    text-align: left;
    margin: auto;
    padding: 2em;
    padding-top: 4em;
    max-width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: auto;
    border-radius: 35px;
    background: linear-gradient(to bottom, #ffffff, #43c5e4);
    display: flex;
    flex-direction: column;
}
.downward-arrow{
  width: 1.5vh;
    height: 1.5vh;
    border-left: 0.5vh solid black;
    border-bottom: 0.5vh solid black;
    transform: rotate(-45deg);
    display: none;
}
  .modules {
    margin-top: 2vh;
    width: 100%;
  }

  .module {
    width: 100%;
    background-color: transparent;
    color: black;
    border-radius: 1em;
    margin-bottom: 1vh;
    padding: 2vh;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .module:hover {
    transform: scale(1.05);
  }

  .active {
    font-weight: normal;
  }

  .module-info {
    display: contents;
    margin-bottom: 1vh;
    font-size: 1em;
  }

  .module-number {
    font-size: 1.5em;
    width: auto;
  }

  .module-name {
    font-size: 1.5em;
    display: block;
    width: 100%;
    white-space: normal;
  }

  .module-details {
    padding-left: 0;
    font-size: 1em;
  }

  ul {
    list-style-type: disc;
    padding-left: 2vw;
  }

  li {
    margin-bottom: 0.5vh;
  }

  .curriculum-outer {
    padding: 2vh;
    min-height: auto;
  }

  .cta-section {
    text-align: center;
    margin-top: 2vh;
  }

  .cta-text {
    font-size: 2em;
    margin-bottom: 1vh;
  }

  .price-box {
    width: 70vw;
    font-size: 2.4vh;
    padding: 1.5vh 3vw;
    margin: 0 auto 1vh;
  }

  .apply-button {
    width: 70vw;
    font-size: 1.8vh;
    padding: 1.5vh 3vw;
    margin: 0 auto;
  }
}

/* Styles for 601px to 768px */


/* Styles for 769px to 992px */
@media screen and (min-width: 769px) and (max-width: 992px) {
  .curriculum-outer {
    padding: 5vh 5vw;
  }
  .cta-section {
    display: flex;
    text-align: center;
    margin-top: 5vh;
    align-items: center;
    flex-direction: column;
}
  .heading {
    font-size: 4.5vh;
  }

  .curriculum-core {
    padding: 5vh;
    max-width: 85vw;
  }

  .modules {
    margin-top: 5vh;
  }

  .module-info {
    font-size: 1.6em;
  }

  .module-number {
    font-size: 3vh;
  }

  .module-name {
    font-size: 2.5vh;
  }

  .cta-text {
    font-size: 4em;
  }

  .price-box {
    width: 45vw;
        font-size: 4.4vh;
        margin: 0;
        margin-bottom: 1vh;
  }

  .apply-button {
    width: 45vw;
    font-size: 2.6vh;
  }
}

/* Styles for 993px to 1200px */
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .curriculum-outer {
    padding: 5vh 5vw;
  }
  .cta-section {
    display: flex;
    text-align: center;
    margin-top: 5vh;
    align-items: center;
    flex-direction: column;
}
  .heading {
    font-size: 4.5vh;
  }

  .curriculum-core {
    padding: 5vh;
    max-width: 85vw;
  }

  .modules {
    margin-top: 5vh;
  }

  .module-info {
    font-size: 1.6em;
  }

  .module-number {
    font-size: 3vh;
  }

  .module-name {
    font-size: 2.5vh;
  }

  .cta-text {
    font-size: 4em;
  }

  .price-box {
    width: 45vw;
        font-size: 4.4vh;
        margin: 0;
        margin-bottom: 1vh;
  }

  .apply-button {
    width: 45vw;
    font-size: 2.6vh;
  }
}
