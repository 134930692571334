.popular-body {
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center; /* Center the main container horizontally */
  align-items: center; /* Center the main container vertically */
  min-height: 100%; /* Ensure the body takes at least the full viewport height */
  padding-top: 2%;
  padding-bottom: 2%;
}

.popular-courses {
  position: relative;
  text-align: left;
  width: 80%;
  padding: 40px;
  padding: 2vw;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 3vw;
  background-color: #ffffff;
}

.popular-courses h2 {
  font-size: 6vh;
  font-size: 3vw;
  color: #43c5e4;
  font-family: 'Montserrat-Semiold', sans-serif;
  font-weight: bold;
  margin-left: 2vh;
}

.popular-courses-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}


.course-card {
  background-color: #43c5e4;
  padding: 2vw;
  /*position: relative;*/
  border-radius: 1vw;
  width: 25vw;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 23vw;
  max-width: max-content;
  max-width: 20vw;
  margin: 20px auto;
  color: white;
  transition: transform 0.3s, box-shadow 0.3s;
  /* Add transition for hover effect */
}

.course-card:hover {
  transform: translateY(-10px);
  /* Move the card up on hover */
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  /* Increase the shadow on hover */
}

.course-card img {
  max-width: 100%;
  height: 40vh;
  margin-bottom: 10px;
}

.course-details {
  text-align: left;
  font-family: Montserrat;
  font-size: 1.2vw;


}

.course-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.original-price {
  text-decoration: line-through;
  color: #ffffff;
}

.discounted-price {
  display: none;
  width: 90px;
  height: 25px;
  border-radius: 7px;
  background-color: #ffffff;
  font-weight: bold;
  color: #43c5e4;
  font-family: "Montserrat Roman";
  text-align: center;
  font-size: larger;
}

/* Media Queries */

/* For devices with a max-width of 600px */
@media (max-width: 600px) {
  .popular-courses {
    width: 90%;
    padding: 20px;
  }

  .popular-courses h2 {
    font-size: 24px;
    margin-left: 0;
  }

  .popular-courses-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    flex-direction: column;
    align-items: center;
}

.course-card {
  display: flex;
  /* width: 72vw; */
  min-width: 81vw;
  margin: 2% 1%;
  /* padding: 42px; */
}
  .course-details {
    text-align: left;
    font-family: Montserrat;
    font-size: 3.2vw;
}

  .course-card img {
    height: 21vw;
    display: block;
    margin: 0 auto;
}

  .course-price {
    flex-direction: column;
    align-items: flex-start;
  }

  .discounted-price {
    font-size: medium;
    width: auto;
    padding: 5px 10px;
    text-align: justify;
  }
}


/* For devices with a min-width of 601px and max-width of 768px */
@media (min-width: 601px) and (max-width: 768px) {
  .popular-courses {
    width: 85%;
    padding: 30px;
  }

  .popular-courses h2 {
    font-size: 30px;
    margin-left: 10px;
  }

  .popular-courses-list {
    justify-content: flex-start;
  }

  .course-card {
    width: 22vw;
    margin: 2% 1%;
    padding: 20px;
  }


  .course-card img {
    height: auto;
  }

  .course-price {
    flex-direction: column;
    align-items: flex-start;
  }

  .discounted-price {
    font-size: large;
    width: auto;
    padding: 5px 15px;
  }
}


/* For devices with a min-width of 769px and max-width: 992px */
@media (min-width: 769px) and (max-width: 992px) {
  .popular-courses {
    width: 80%;
    padding: 35px;
  }

  .popular-courses h2 {
    font-size: 35px;
    margin-left: 15px;
  }

  .popular-courses-list {
    flex-direction: row;
  }

  .course-card {
    width: 22vw;
    margin: 2% 1%;
    padding: 20px;
  }


  .course-card img {
    height: auto;
  }

  .course-price {
    flex-direction: column;
    align-items: flex-start;
  }

  .discounted-price {
    font-size: larger;
    width: auto;
    padding: 5px 20px;
  }
}


/* For devices with a min-width of 993px and max-width of 1200px */
@media (min-width: 993px) and (max-width: 1200px) {
  .popular-courses {
    width: 75%;
    padding: 40px;
  }

  .popular-courses h2 {
    font-size: 36px;
    margin-left: 18px;
  }

  .popular-courses-list {
    flex-direction: row;
  }

  .course-card {
    width: 22vw;
    margin: 2% 1%;
    padding: 20px;
  }

  .course-card img {
    height: auto;
  }

  .course-price {
    flex-direction: column;
    align-items: flex-start;
  }

  .discounted-price {
    font-size: larger;
    width: auto;
    padding: 5px 25px;
  }
}