/* AboutHero.css */

.about-hero {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust the height as needed */
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 0%, /* White */
        
        rgba(173, 216, 230, 1) 25%, /* Light blue */
        rgba(135, 206, 250, 1) 50%, /* Light sky blue */
        rgba(70, 130, 180, 1) 100% /* Medium steel blue (peak blue) */
    );
    

}

.content-about {
    display: flex;
    align-items: center;
    height: 100%;
}

.text-container-about {
    width: 100%; /* Adjust width as needed */
    margin: 0 auto; /* This will center the text container */
    margin-left: 10%;
    margin-right: 10%;
    text-align: center; /* Center align text */
}

.title-about {
    font-family: 'Montserrat-Bold', sans-serif;
    font-weight: bold;
    font-size: 3vw;
    color: #000000;
}

.description-about {
    font-family: Montserrat;
    font-size: 2vw;
    color: #000000;
    margin-top: 2vh; /* Adjust margin as needed */
}





@media screen and (orientation: portrait) {
    .about-hero{
        height: 45vh;
    }
    .text-container-about {
        width: 90%; /* Adjust width for smaller screens */
        margin: 0 auto; /* Center the text container */
        margin-top: -15%; /* Adjust margin-top for spacing */
        margin-bottom: -7%; /* Adjust margin-bottom for spacing */
    }

    .title-about {
        font-size: 3vh; /* Reduce font size for smaller screens */
    }

    .description-about {
        font-size: 2vh; /* Reduce font size for smaller screens */
    }

    .image-container-about {
        display: none; /* Hide the image container for smaller screens */
    }

    
}
