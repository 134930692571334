/* ComingSoon.css */

.coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1) 0%, /* White */
        
        rgba(173, 216, 230, 1) 25%, /* Light blue */
        rgba(135, 206, 250, 1) 50%, /* Light sky blue */
        rgba(70, 130, 180, 1) 100% /* Medium steel blue (peak blue) */
    );
    
    color: #fff; /* White text color */
    text-align: center;
  }
  
  .coming-soon-text {
    font-size: 48px; /* Large font size */
    font-weight: bold;
    animation: pulse 1.5s infinite; /* Pulse animation */
    
  }
  
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  