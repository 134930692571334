/* components/Others.css */
.other-container{
  
  background-color: #000; /* Set the background color of the body to black */
    color: #fff;
    
    text-align: center;
    font-family: Montserrat;
}
  
  /* Additional styling for others components if needed */
  