/*responsive : yes*/
/*responsive test-status: */
.footer {
  position: relative;
  width: 100vw;
  height: 45vh; /* Adjust the height as needed */
 
}

.text-container11 {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  width: 30vw;
  padding-top: 3vh;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-left: 4.5vw;
}
.Topic11{
  font-family: 'Montserrat-Semibold', sans-serif;

  font-size: 5vh;
    font-weight: 700;
}
.Description{
  font-size: 2.4vh;
}
.EnButton-blue {
  padding: 5vh 5vw; /* Adjust the padding to increase the size */
  margin-left: -2%;
  background-color:transparent;
 width: 8vw;
  border: none;
  cursor: pointer;
  background-image: url('../assets/enroll-now.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  
  transition: background-color 0.3s ease;
}

.EnButton-blue:hover {
  
  transform: scale(1.1); /* Adjust the scale factor for enlargement */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Include the transform property in the transition */
}

.right-container {
  position:relative;
  width: 40%; /* Adjust the width as needed */
  height: 100%;
  overflow: visible;
  align-items: center;
}

.right-container {
  display: flex; /* Use flexbox to make the columns side by side */
  justify-content: space-between; /* Distribute the columns evenly */
 margin-left: 0%;
 padding-bottom: 10vh;
 padding-left: 15vh;
}



.column {
  width: calc(33.33% - 10px); /* Adjust the width of each column */
  margin-right: 10px; /* Add some margin between columns */
}

.column:last-child {
  margin-right: 0; /* Remove margin from the last column */
}

.column h3 {
  font-family: 'Montserrat-Semibold', sans-serif;

  font-size: 2.5vh; /* Adjust the font size of the column headings */
}

.column ul {
  list-style-type: none; /* Remove default list styles */
  padding: 0; /* Remove default padding */
}

.column ul li {
  margin-bottom: 5px; /* Add some space between list items */
}

.column ul li a {
  text-decoration: none; /* Remove default underline from links */
  color: #333; /* Set the color of the links */
  font-size: 2vh;
}


@media screen and (max-width: 768px) {
  .footer {
    position: relative;
    width: 100%;
    height: 50vh; /* Adjust the height as needed */
   
  }
  .Topic11{
    font-size: 6vw;
  }
  .Description{
    font-size: 3vw;
  }
  /* Styles for screens up to 768px width */
  .footer {
    height: auto; /* Adjust the height to fit the content */
  }
  
  .content {
    flex-direction: column; /* Change to column layout for smaller screens */
  }
  .EnButton-blue {
    padding: 3vh 9vw; /* Adjust the padding to increase the size */
  
  background-color:transparent;
 width: 25vw;
  border: none;
  cursor: pointer;
  background-image: url('../assets/enroll-now.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  
  transition: background-color 0.3s ease;
}
.column h3 {
  font-size: 2vh;
}
  .right-container {
    width: 90%; /* Adjust the width to take up more space on smaller screens */
    display: flex; /* Use flexbox to make the columns side by side */
    margin-right: 12vw;
    justify-content: space-between; /* Distribute the columns evenly *//* Center the container horizontally and add some top margin */
  }
  
  
  .text-container11 {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
    width: auto;
    padding: 2vw;
    margin-right: 0;
}
  .column {
    width: calc(34.33% - 10px); /* Adjust the width of each column */
    margin-right: 17%; /* Add some margin between columns */
  }
  
  .column:last-child {
    margin-right: 0; /* Remove margin from the last column */
  }
}
