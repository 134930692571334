.multi-step-form {
  background: none;
  background-size: cover;
  padding: 2%;
  border-radius: 4%;
  width: 30%; /* Adjust the width as needed */
  margin: auto; /* Center the form */
  text-align: left;
  position: relative;
}

.multi-step-form .close-button {
  position: absolute;
  top: 2%; /* Adjust to position the button correctly */
  right: -70%; /* Adjust to position the button correctly */
  background: none;
  border: none;
  color: black;
  font-size: 24px; /* Adjust the size of the cross */
  cursor: pointer;
  transition: transform 0.2s ease-in-out; /* Hover animation */
}

.multi-step-form .close-button:hover {
  transform: scale(1.2); /* Scale up the button on hover */
}

.multi-step-form .form-logo {
  display: block;
  margin: 0 auto 2%; /* Center the image and add space below it */
  width: 30%; /* Adjust the width as needed */
  height: auto;
  padding: 10%;
}

.multi-step-form .back-button {
  position: relative;
  top: 1em; /* Adjust to align with the progress bar */
  left: 0;
  background: none;
  border: none;
  color: black;
  font-size: 100%;
  cursor: pointer;
  font-weight: bold;
  text-align: left;
  margin-bottom: 1em; /* Add space below the button */
}

.multi-step-form .progress-bar {
  width: 100%;
  height: 5px; /* Adjust the height as needed */
  background-color: #e0e0e0; /* Light grey background */
  display: flex;
  justify-content: left;
  margin-top: 2em; /* Space to account for the back button */
  margin-bottom: 1.5em;
  border-radius: 2px;
  position: relative;
}

.multi-step-form .progress {
  height: 100%;
  background-color: #000; /* Black progress indicator */
  border-radius: 2px;
}

.multi-step-form form {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Ensure elements stretch to the container width */
  position: relative;
}

.error {
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: rgb(199, 0, 0);
  font-size: 0.9em;
  margin-left: 5px;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-group label {
  margin-bottom: 5px;
}

.input-group input,
.input-group select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
}

.input-group .error {
  margin-top: -5px;
  margin-bottom: 10px;
  display: inline-block;
}

.next-button,
.back-button,
.submit-button {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.next-button:hover,
.back-button:hover,
.submit-button:hover {
  background-color: #444;
}

.multi-step-form label {
  margin: 10px 0 5px;
  color: black; /* Adjusted for better visibility */
  font-size: 100%;
}

.multi-step-form input {
  margin-bottom: 4%;
  padding: 2%;
  border: 1px solid black;
  border-radius: 4px;
  width: 100%; /* Ensure full width within form container */
  background-color: transparent;
  color: black; /* Changed to black for better readability */
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}

.multi-step-form button {
  padding: 2%;
  margin: 5px 0;
  border: none;
  border-radius: 4px;
  background-color: #000;
  color: white;
  cursor: pointer;
  text-align: center; /* Center-align text in buttons */
  width: 100%; /* Ensure buttons take full width */
}

.multi-step-form button[type="submit"] {
  background-color: #000;
  text-align: center;
}

.multi-step-form button:hover {
  opacity: 0.8;
}

.multi-step-form .choice-button {
  display: block;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid black;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  text-align: left; /* Left-align text in buttons */
  color: black;
}

.multi-step-form .choice-button.selected {
  background-color: rgb(57, 73, 88);
  color: black;
}

.multi-step-form .choice-button:hover {
  background-color: rgba(87, 66, 66, 0.1);
}

/* Styling for Area of Interest and Sub Courses dropdowns */
.multi-step-form select {
  margin-bottom: 4%;
  padding: 2%;
  border: 1px solid black;
  border-radius: 4px;
  width: 100%; /* Ensure full width within form container */
  background-color: transparent;
  color: black; /* Changed to black for better readability */
  font-size: 16px; /* Adjust font size if needed */
}

/* Additional styling for better appearance */
.multi-step-form option {
  background-color: white;
  color: black; /* Ensure text is visible */
}

.multi-step-form select:focus {
  outline: none; /* Remove default outline */
  border-color: #000; /* Highlight border on focus */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Add subtle shadow on focus */
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .multi-step-form {
    width: 90%;
    height: 100%;
    position: relative;
    padding: 0%;
    margin-bottom: 5vh;
  }

  .multi-step-form .form-logo {
    width: 50%; /* Adjust for smaller screens */
    padding: 1%;
  }

  .multi-step-form .back-button {
    top: 0; /* Adjust for smaller screens */
    margin-bottom: 0; /* Adjust spacing */
  }

  .multi-step-form .progress-bar {
    margin-top: 0.5em; /* Adjust for smaller screens */
    margin-bottom: 0.5em;
  }

  .multi-step-form .close-button {
    top: .5%; /* Adjust as needed for smaller screens */
    right: -40%; /* Adjust as needed for smaller screens */
    font-size: 18px; /* Adjust font size for smaller screens */
  }
}
