.download-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.download-form-container {
  background: #fff;
  padding: 2em;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.download-form-container h2 {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 1.5em;
  text-align: center;
}

.download-form-container form label {
  display: block;
  margin-bottom: 0.5em;
  font-weight: bold;
  font-size: 1em;
}

.download-form-container form input {
  width: 100%;
  padding: 0.5em;
  margin-top: 0.25em;
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

.download-form-container .button-group {
  display: flex;
  justify-content: space-between;
}

.download-form-container .button-group button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.75em 1.5em;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.download-form-container .button-group button:hover {
  background-color: #0056b3;
}

.close-button {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.close-button:hover {
  color: red;
}
