@keyframes popUpAnimation {
  0% {
    transform: scale(0.8); /* Start with a smaller size */
  }
  50% {
    transform: scale(1.2); /* Increase size halfway through */
  }
  100% {
    transform: scale(1); /* Return to normal size */
  }
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../../Resources/Fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


.scatter-around-ece {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scatter-around-ece img {
  width: 5vw; /* Use viewport width for responsive size */
  height: auto; /* Maintain aspect ratio */
  margin: 1vw; /* Adjust spacing between images using vw */
  animation: popUpAnimation 3s ease alternate infinite; /* Adjust duration and timing function */
}

.ece-and-eee {
  position: relative;
  width: 100%;
  height: 40vw;
}

.content-ece {
  display: flex;
  align-items: center;
  height: 40vw;
}

.text-container-ece {
  width: 35%;
  margin: 0 auto; /* This will center the text container */
  margin-left: 10vw; /* Adjust the left margin to shift it slightly towards the left */
}

.title-ece {
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: bold;
  font-size: 3vw; /* Use viewport width for responsive font size */
  color: white;
}

.description-ece {
  font-family: Montserrat;
  font-size: 1.5vw; /* Use viewport width for responsive font size */
  color: white;
  margin-top: 2vh;
}

.round-button-ece {
  padding: 1vw 2vw; /* Increased padding using viewport width */
  border-radius: 4vw; /* Increased border radius using viewport width */
  background-color: #43c5e4;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 1.5vw; /* Increased font size using viewport width */
  margin-top: 3vh;
}

.image-container-ece {
  width: 65%;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.stack-imgs-ece img {
  position: absolute;
}

.stack-imgs-ece img:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%; /* Use viewport width for responsive size */
  height: auto;
  margin-left: 3vw;
}



.stack-imgs-ece img:nth-child(4) {
  top: 10vh; /* Adjust the spacing between images using vh */
  left: 0vw;
  width: 40vw; /* Use viewport width for responsive size */
  height: 40vw;
  margin-left: 15vw;
}


/* Add more rules for additional images if needed */

@media screen and (orientation: portrait)  {
  .ece-and-eee {
    flex-direction: column;
    justify-content: flex-start;
    height: 160vw;

  }

  .content-ece {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 160vw;
  }

  .text-container-ece {
    width: 90%;
    margin-left: 5%;
    margin-top: 5%;
  }

  .title-ece {
    font-family: 'Montserrat-Bold', sans-serif;
    font-weight: bold;
    font-size: 7vw; /* Adjust font size for smaller screens */
    color: white;
  }

  .description-ece {
    font-size: 3.5vw;
  }

  .round-button-ece {
    font-size: 4vw;
    padding: 2vw 4vw;
    }

  .image-container-ece {
    width: 100%;
    height: 50%; /* Adjust height as needed */
    top: 5%;
  }

  .stack-imgs-ece img:nth-child(1) {
    width: 100%; /* Use viewport width for responsive size */
    margin-left: 2vw;

    
  }



  .stack-imgs-ece img:nth-child(4) {
    width: 100vw; /* Use viewport width for responsive size */
    height: 100vw;
    margin-top: 0vh; /* Adjust margin using viewport height */
    left: -15vw;
    top: -10vh;
  }


}
