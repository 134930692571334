/*responsive : yes*/
/*responsive test-status: */


@keyframes moveLeft {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.body h2{
  font-size: 3vw;
  font-family: 'Montserrat-Semiold', sans-serif;

}

.body {
  padding: 40px;
  background-color: #000; /* Set the background color of the body to black */
  color: #fff;
  text-align: center;
}

.testimonials {
  display: flex;
  justify-content: flex-start;
  overflow: hidden; 
  margin-bottom: 40px;
  width: 100%; /* Ensure the container takes full width */ 
  
}

.testimonial-card {
  animation: moveLeft 17s linear infinite;
  flex: 0 0 auto;
  background-color: #000000; /* Changed to black */
  padding: 20px;
  border: 2px solid #ffffff; /* Add white border */
  border-radius: 10px; /* Increased border-radius for a smoother 3D effect */
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2); /* Added box-shadow for 3D effect */
  max-width: 400px;
  margin: 0 10px;
  margin-top: 2vh;
  color: #ffffff;
}
.testimonials:hover .testimonial-card {
  animation-play-state: paused;
}
.testimonial-author {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.testimonial-author img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {

  .body {
    padding: 5vh;
    background-color: #000; /* Set the background color of the body to black */
    color: #fff;
    text-align: center;
  }
  
  .testimonials {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Disable vertical scrolling */
    margin-bottom: 40px;
    width: 100%; /* Ensure the container takes full width */
    scroll-snap-type: x mandatory; /* Smooth scrolling with snap points */
  }
  
  .testimonial-card {
    animation: moveLeft 15s linear infinite;
    flex: 0 0 auto;
    background-color: #fff; /* Changed to white */
    padding: 20px;
    border-radius: 10px; /* Increased border-radius for a smoother 3D effect */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Added box-shadow for 3D effect */
    max-width: 80%;
    margin: 0 10px;
    color: #000;
  }
  
  .testimonial-author {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .testimonial-author img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
}