/* RuddoAdv.css */
.ruddo-adv-outer {
  height: 100% ;
  padding: 0 10% 0 10%;
background-color: #000; /* Set the background color of the body to black */
  color: #fff;
  text-align: center;
}



.ruddo-adv {
  text-align: center; /* Align the text to the left */
  max-width:100%; /* Set a maximum width for the section */
  /*border-radius: 20px;  Round the borders */
  box-shadow: 0 0 10em rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  padding: 2vh;
  height: 50%;
  border-radius: 6vw 0 0 0;
  background-color: #ffffff;
  
}

.ruddo-adv h2 {
  font-size: 6vh;
  color: black;
  font-family: 'Montserrat-Semibold', sans-serif;
  font-weight: bold;
}

.subtext {
  font-size: 3.7vh;
  width: 60vw;
  height: 10vh;
  padding: auto;
  color: black;
  margin-bottom: 3vw;
  width: 100%;
  
}

.ruddo-adv-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.advantage-card {
  width: 15vw;
  height: 6vw;
  background-color: #43c5e4;
  margin: 1vw;
  padding: 2vw;
  box-shadow: 0 1em 2em rgba(0, 0, 0, 0.2);
  display: flex;
  border-radius:1.5vw;
}

.logo11 {
 
  background-color: white;
  margin: 10%;
  margin-left: 0;
  width: 6vw;
  height: 4vw;

}

.logo11 img {
  width:80%;
  height: 90%;
}

.logo111 {
 
  background-color: white;
  margin: 10%;
  margin-left: 0;
  width: 12vw;
  height: 4vw;

}

.logo111 img {
  width:80%;
  height: 90%;
}

.advantage {
  font-family: Montserrat;
  font-size: 1.2vw;
  color: #ffffff;
  text-align: left;
}



@media screen and (orientation: portrait) {
  .logo11 {
    display: none;
    width:0em;
    height: 0em;
     /* Add background color for the logo */
    margin: auto;
    margin-left: 0;
  }

  .logo11 img {
    width:0em;
    height: 0em;
  }
  .logo111 {
    display: none;
    width:0em;
    height: 0em;
     /* Add background color for the logo */
    margin: auto;
    margin-left: 0;
  }

  .logo111 img {
    width:0em;
    height: 0em;
  }
  .ruddo-adv-outer {
    padding: 0 5vh 0 5vh;
  background-color: #000; /* Set the background color of the body to black */
    color: #fff;
    align-items: center;
    text-align: center;
}
  .ruddo-adv {
    padding: 2vh;
    width: 85vw;
    border-radius: 6vw 6vw 0 0;

  }

  .ruddo-adv h2 {
    font-size: 6vw;
  }

  .subtext {
    align-self: center;
    font-size: 3.5vw;
    width: 100%;
    margin-left: 0;
  }

  .advantage-card {
    width: 18vw;
    height: 8vw;
    padding: 1.5vw;
    margin: 2%;
    display: flex;          /* Added flexbox properties */
    align-items: center;    /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    
  }

  
  .advantage {
    font-size: 2vw;
    color: #ffffff;
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 0;
    text-align: left;    /* Center text horizontally */
  }
  .ruddo-adv-list{
    margin-top: 6vw;
  }
}


