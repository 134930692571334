/* components/Hero.css */
.HeroIntern {
    background: linear-gradient(
      to bottom,
      rgb(226, 253, 252) 0%,
     
      rgba(173, 216, 230, 1) 15%, /* Light blue */
      rgba(135, 206, 250, 1) 25%, /* Light sky blue */
      rgba(70, 130, 180, 1) 50%, /* Medium steel blue (peak blue) */
      rgba(135, 206, 250, 1) 75%, /* Light sky blue */
      rgba(173, 216, 230, 1) 85%, /* Light blue */
      rgba(255, 255, 255, 1) 95%, /* Adjust the color stop for white at the bottom */
      rgba(255, 255, 255, 1) 100%
    );
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000000; /* Text color on the hero section */
  }

  @media screen and (orientation: portrait) {
/* components/Hero.css */
.HeroIntern {

  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; /* Adjust the height as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000; /* Text color on the hero section */
}
  }


  