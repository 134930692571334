/* Biomed.css */
@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../../Resources/Fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.biomed {
  position: relative;
  width: 100%;
  height: 40vw;
}

.content-biomed {
  display: flex;
  align-items: center;
  height: 40vw;
}

.text-container-biomed {
  width: 30%;
  margin: 0 auto;
  margin-left: 10vw; /* Adjust the left margin using viewport width */
}

.title-biomed {
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: bold;
  font-size: 3vw; /* Use viewport width for font size */
  color: white;
}

.description-biomed {
  font-family: Montserrat, sans-serif;
  font-size: 1.5vw; /* Use viewport width for font size */
  color: white;
  margin-top: 2vh; /* Use viewport height for spacing */
}

.round-button-biomed {
  padding: 1vw 2vw; /* Increased padding using viewport width */
  border-radius: 4vw; /* Increased border radius using viewport width */
  background-color: #43c5e4;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 1.5vw; /* Increased font size using viewport width */
  margin-top: 3vh;
}

.image-container-biomed {
  width: 65%;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.stack-imgs-biomed img {
  position: absolute;
}

.stack-imgs-biomed img:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%; /* Use viewport width for image size */
  height: auto;
  margin-left: 3vw; /* Use viewport width for margin */
}

.stack-imgs-biomed img:nth-child(2) {
  top: 5vh; /* Use viewport height for spacing */
  left: 0;
  width: 20vw;
  height: auto;
  margin-top: 20vh;
}

.stack-imgs-biomed img:nth-child(3) {
  top: 5vh;
  left: 0;
  width: 20vw;
  height: auto;
  margin-top: 20vh;
  margin-left: 40vw;
}

.stack-imgs-biomed img:nth-child(4) {
  top: 10vh; /* Use viewport height for spacing */
  left: 0;
  width: 25vw;
  height: auto;
  margin-left: 20vw;
}

.scatter-around-biomed {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 20%; /* Adjust the top position using percentages */
  left: 50%; /* Center the scatter images */
  transform: translateX(-50%);
}

.scatter-around-biomed img {
  width: 5vw; /* Use viewport width for image size */
  height: auto;
  margin: 0 0.5vw; /* Adjust the spacing between images */
  position: relative;
  animation: none;
}

/* Define the positions for the scatter images */
.scatter-around-biomed img:nth-child(1) {
  top: 40vh; /* Use viewport height for spacing */
  left: -15vw;
}

.scatter-around-biomed img:nth-child(2) {
  top: 1vh; /* Use viewport height for spacing */
  left: -5vw;
}

.scatter-around-biomed img:nth-child(3) {
  top: 10vh; /* Use viewport height for spacing */
  left: -20vw;
}

.scatter-around-biomed img:nth-child(4) {
  top: 30vh; /* Use viewport height for spacing */
  left: -22vw;
}

/* Add styles for the fourth scatter image if needed */
@media screen and (orientation: portrait)  {
  .biomed {
      flex-direction: column;
      justify-content: flex-start;
      height: 160vw;

  }

  .content-biomed {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 160vw;
  }

  .text-container-biomed {
      width: 90%;
      margin-left: 5%;
      margin-top: 5%;
  }

  .title-biomed {
      font-size: 7vw; /* Adjust font size for mobile view */
  }

  .description-biomed {
    font-size: 3.5vw;
  }

  .round-button-biomed {
      font-size: 4vw; /* Adjust font size for mobile view */
      padding: 2vw 4vw;
    }

  .image-container-biomed {
      width: 100%;
      height: 50%; /* Adjust height as needed */
      top: 5%;
  }

  .stack-imgs-biomed img:nth-child(1) {
      width: 100%;
      margin-left: 1em;
  }



  .stack-imgs-biomed img:nth-child(4) {
      transform: scale(2);
      top: 12vh;
      left: 15vw;
  }

  .scatter-around-biomed {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }

  .scatter-around-biomed img {
      display: none;
      width: 3em;
      margin: 0 2px;
  }

  .scatter-around-biomed img:nth-child(1),
  .scatter-around-biomed img:nth-child(2),
  .scatter-around-biomed img:nth-child(3),
  .scatter-around-biomed img:nth-child(4),
  .scatter-around-biomed img:nth-child(5),
  .scatter-around-biomed img:nth-child(6) {
      top: 10%;
      left: 10%;
  }
}
