/* InternshipPage.css */
.internshipPage {
  position: relative;
  overflow: visible;
  overflow-x: hidden;
}

.internshipPage.expanded .popular-courses,
.internshipPage.expanded .subscribe,
.internshipPage.expanded .footer {
  margin-top: 200%; /* Adjust this value as needed */
}


