.hero-options {
  position: relative;
  width: 100%;
  height: 200vh; /* Adjust the height as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.round-image {
  position: relative;
  width: 56vw;
  height: 53vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.round-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 1;
  z-index: 1;
  transition: all 0.5s linear;
}

.square-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 0;
  transition: opacity 2s ease-in-out;
  animation: fade 10s ease infinite;
}

.square-image img:hover {
  opacity: 1;
}

@keyframes fade {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.internship {
  width: 35vw;
  text-align: center;
  color: #fff;
  padding: 20px;
  z-index: 2;
}

.internship h1 {
  font-family: 'Montserrat-Semibold', sans-serif;
  font-weight: 900;
  font-size: 5vh;
  color: #000000;
}

.internship p {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 2.7vh;
  color: #000000;
  margin-top: 20px; /* Adjust margin as needed */
}

.ExButton {
  padding: 35px 90px; /* Adjust the padding to increase the size */
  font-size: 1.5em;
  height: 8vh;
  width: 25vh;
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  background-image: url('../assets/Asset\ 14.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
  transition: background-color 0.3s ease;
}

.ExButton:hover {
  transform: scale(1.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Media Queries */

/* For devices with a max-width of 600px */
@media (max-width: 600px) {
  .hero-options {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  .round-image {
    width: 90vw;
    height: 40vh;
  }
  .square-image img {
    display: none;
  }

  .internship {
    width: 90%;
  }

  .internship h1 {
    font-size: 3vh;
  }

  .internship p {
    font-size: 2vh;
    margin-top: 10px;
  }

  .ExButton {
    padding: 20px 45px;
    font-size: 1em;
    height: 6vh;
    width: 20vh;
  }
}

/* For devices with a min-width of 601px and max-width of 768px */
@media (min-width: 601px) and (max-width: 768px) {
  .round-image {
    width: 80vw;
    height: 45vh;
  }
  .hero-options {
    position: relative;
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  .internship {
    width: 80%;
  }
  .square-image img {
    display: none;
  }

  .internship h1 {
    font-size: 4vh;
  }

  .internship p {
    font-size: 2.2vh;
    margin-top: 15px;
  }

  .ExButton {
    padding: 25px 60px;
    font-size: 1.2em;
    height: 7vh;
    width: 22vh;
  }
}

/* For devices with a min-width of 769px and max-width: 992px */
@media (min-width: 769px) and (max-width: 992px) {
  .round-image {
    width: 70vw;
    height: 50vh;
  }

  .internship {
    width: 70%;
  }

  .internship h1 {
    font-size: 4.5vh;
  }

  .internship p {
    font-size: 2.5vh;
    margin-top: 18px;
  }

  .ExButton {
    padding: 30px 75px;
    font-size: 1.3em;
    height: 7.5vh;
    width: 23vh;
  }
}

/* For devices with a min-width of 993px and max-width of 1200px */
@media (min-width: 993px) and (max-width: 1200px) {
  .round-image {
    width: 60vw;
    height: 53vh;
  }

  .internship {
    width: 60%;
  }

  .internship h1 {
    font-size: 5vh;
  }

  .internship p {
    font-size: 2.7vh;
    margin-top: 20px;
  }

  .ExButton {
    padding: 35px 90px;
    font-size: 1.5em;
    height: 8vh;
    width: 25vh;
  }
}
