/* components/Others.css */
.other-container-about{
  
    background-color: #000; /* Set the background color of the body to black */
      color: #fff;
      
      text-align: center;
  }
    
    /* Additional styling for others components if needed */
    