/* Mechanical.css */
@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../../Resources/Fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.mechanical {
  position: relative;
  width: 100%;
  height: 40vw;
}

.content-mechanical {
  display: flex;
  align-items: center;
  height: 40vw;
}

.text-container-mechanical {
  width: 35%;
  margin: 0 auto;
  margin-left: 10vw; /* Adjust the left margin using viewport width */
}

.title-mechanical {
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: bold;
  font-size: 3vw; /* Use viewport width for font size */
  color: white;
}

.description-mechanical {
  font-family: Montserrat, sans-serif;
  font-size: 1.5vw; /* Use viewport width for font size */
  color: white;
  margin-top: 2vh; /* Use viewport height for spacing */
}

.round-button-mechanical {
  padding: 1vw 2vw; /* Increased padding using viewport width */
  border-radius: 4vw; /* Increased border radius using viewport width */
  background-color: #43c5e4;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 1.5vw; /* Increased font size using viewport width */
  margin-top: 3vh;
}

.image-container-mechanical {
  width: 65%;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.stack-imgs-mechanical img {
  position: absolute;
}

.stack-imgs-mechanical img:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%; /* Use viewport width for image size */
  height: auto;
  margin-left: 3vw; /* Use viewport width for margin */
}


.stack-imgs-mechanical img:nth-child(4) {
  top: 10vh; /* Use viewport height for spacing */
  right: 5vw;
  width: 45vw;
  height: auto;
  margin-left: 5vw; /* Use viewport width for margin */
}


/* Add styles for the fourth scatter image if needed */
@media screen and (orientation: portrait)  {
  .mechanical {
      flex-direction: column;
      justify-content: flex-start;
      height: 160vw;

  }

  .content-mechanical {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 160vw;
  }

  .text-container-mechanical {
      width: 90%;
      margin-left: 5%;
      margin-top: 5%;
  }

  .title-mechanical {
      font-size: 7vw; /* Adjust font size for mobile view */
  }

  .description-mechanical {
    font-size: 3.5vw;
  }

  .round-button-mechanical {
    font-size: 4vw;
    padding: 2vw 4vw;
    }

  .image-container-mechanical {
      width: 100%;
      height: 50%; /* Adjust height as needed */
      top: 5%;
  }

  .stack-imgs-mechanical img:nth-child(1) {
      width: 94%;
      margin-left: 1em;
  }

  .stack-imgs-mechanical img:nth-child(4) {
      transform: scale(0.47);
      width: 170vw;
      top: -22vh;
      left: -35vw;
  }

 
}
